var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from 'vuex-class';
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
var collectionManageModule = namespace("collection-manage");
var CommentModify = /** @class */ (function (_super) {
    __extends(CommentModify, _super);
    function CommentModify() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.model = {
            caseId: "",
            commentType: "COMMENT",
            commentContent: "",
            caseType: ""
        };
        _this.loading = {
            state: false
        };
        _this.rules = {
            commentContent: [
                { required: true, message: "请输入评语内容", trigger: "blur" }
            ]
        };
        return _this;
    }
    CommentModify.prototype.close = function () {
        var form = this.$refs["form"];
        form.resetFields();
    };
    /**
     * 添加评语提交
     */
    CommentModify.prototype.commit = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.model.caseId = _this.caseId;
            _this.model.caseType = _this.otherData.caseType;
            var model = Object.assign({}, _this.model);
            _this.loading.state = true;
            _this.caseFollowRecordService.addTag(model, _this.loading).subscribe(function (data) {
                _this.$message.success("添加评语成功");
                _this.close();
            });
        });
    };
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], CommentModify.prototype, "caseFollowRecordService", void 0);
    __decorate([
        collectionManageModule.State
    ], CommentModify.prototype, "otherData", void 0);
    __decorate([
        Prop()
    ], CommentModify.prototype, "caseId", void 0);
    __decorate([
        Prop()
    ], CommentModify.prototype, "personalId", void 0);
    __decorate([
        Emit("close")
    ], CommentModify.prototype, "close", null);
    CommentModify = __decorate([
        Component({
            components: {}
        })
    ], CommentModify);
    return CommentModify;
}(Vue));
export default CommentModify;
