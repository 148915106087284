import AppForAuditQuery from "./app-for-audit-query.vue";
import AppInfoQuery from "./app-info-query.vue";
import CoDebtQuery from "./co-debt-query.vue";
import DetailedInfoQuery from "./detailed-info-query.vue";
import MultiPositionQuery from "./multi-position-query.vue";
import PublicCasesQuery from "./public-cases-query.vue";
import ReportAppQuery from "./report-app-query.vue";
import TurnCasesApply from "./turn-cases-apply.vue";
import StayCaseQuery from "./stay-case-query.vue";
import SupplementPaymentQuery from "./supplement-payment-query.vue";
import WaiverAppQuery from "./waiver-app-query.vue";
import CommentModify from "./comment-modify.vue";
import ReductionZeroApplication from "./reduction-zero-application.vue";
import AmountSubsidy from "./amount-subsidy.vue";
/**
 * 调收执行页面 快捷菜单用到的组件
 */
export var components = {
    AppForAuditQuery: AppForAuditQuery,
    AppInfoQuery: AppInfoQuery,
    CoDebtQuery: CoDebtQuery,
    DetailedInfoQuery: DetailedInfoQuery,
    MultiPositionQuery: MultiPositionQuery,
    PublicCasesQuery: PublicCasesQuery,
    ReportAppQuery: ReportAppQuery,
    StayCaseQuery: StayCaseQuery,
    SupplementPaymentQuery: SupplementPaymentQuery,
    WaiverAppQuery: WaiverAppQuery,
    CommentModify: CommentModify,
    ReductionZeroApplication: ReductionZeroApplication,
    AmountSubsidy: AmountSubsidy,
    TurnCasesApply: TurnCasesApply,
};
// export const hotKeysConfig = [
//   { name: "多维定位", component: "multi-position-query", auth: 724 },
//   { name: "共债信息", component: "co-debt-query", noRefresh: true, auth: 725 },
//   { name: "查账申请", component: "app-for-audit-query", auth: 738 },
//   { name: "减免申请", component: "waiver-app-query", auth: 739 },
//   { name: "减免清零申请", component: "reduction-zero-application" , auth: -1 },
//   { name: "补款申请", component: "supplement-payment-query", auth: 723 },
//   { name: "补款金额申请", component: "amount-subsidy",auth: -1  },
//   { name: "报案申请", component: "report-app-query", auth: 740 },
//   { name: "留案申请", component: "stay-case-query", auth: 741 },
//   { name: "公共案件", component: "public-cases-query", auth: 742 },
//   { name: "申请资料", component: "app-info-query", auth: 728 },
//   //{ name: "详细资料", component: "detailed-info-query" },
//   { name: "添加评语", component: "comment-modify", auth: 743 }
// ]
export var hotKeysConfig = [
    // { name: "多维定位", component: "multi-position-query" },
    { name: "共债信息", component: "co-debt-query", noRefresh: true },
    // { name: "查账申请", component: "app-for-audit-query" },
    { name: "减免申请", component: "waiver-app-query" },
    // { name: "减免清零申请", component: "reduction-zero-application"  },
    // { name: "补款申请", component: "supplement-payment-query", },
    // { name: "补款金额申请", component: "amount-subsidy"  },
    { name: "报案申请", component: "report-app-query" },
    { name: "留案申请", component: "stay-case-query" },
    { name: "公共案件", component: "public-cases-query" },
    // { name: "申请资料", component: "app-info-query" },
    { name: "添加评语", component: "comment-modify" },
    { name: "流转申请", component: "turn-cases-apply" },
];
