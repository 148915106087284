var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import DataBox from '~/components/common/data-box.vue';
import SupplementPayment from '~/components/collection-manage/collection-application/supplement-payment.vue';
import { PageService } from '~/utils/page.service';
import { SortService } from '~/utils/sort.service';
import { Dependencies } from '~/core/decorator';
import { namespace } from 'vuex-class';
import { CaseApplyOperateService } from '~/services/domain-service/case-apply-operate.service';
var collectionManageModule = namespace('collection-manage');
var SupplementPaymentQuery = /** @class */ (function (_super) {
    __extends(SupplementPaymentQuery, _super);
    function SupplementPaymentQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.dataSet = [];
        _this.dialog = {
            supplementPayment: false,
            supplementPaymentDetails: false,
        };
        _this.queryParamsModel = {
            caseId: '',
            applyType: 'SUPPLEMENT_APPLY',
        };
        return _this;
    }
    SupplementPaymentQuery.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.queryParamsModel.caseId = this.caseId;
        this.caseApplyOperateService
            .caseApplySearch(this.queryParamsModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    /**
     * 查看详情
     */
    SupplementPaymentQuery.prototype.detailsClick = function (data) {
        this.dialog.supplementPaymentDetails = true;
    };
    __decorate([
        Dependencies(PageService)
    ], SupplementPaymentQuery.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], SupplementPaymentQuery.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseApplyOperateService)
    ], SupplementPaymentQuery.prototype, "caseApplyOperateService", void 0);
    __decorate([
        collectionManageModule.State
    ], SupplementPaymentQuery.prototype, "caseId", void 0);
    SupplementPaymentQuery = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                SupplementPayment: SupplementPayment,
            },
        })
    ], SupplementPaymentQuery);
    return SupplementPaymentQuery;
}(Vue));
export default SupplementPaymentQuery;
