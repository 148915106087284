var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import SvgIcon from "~/components/common/svg-icon.vue";
import CommonTitle from "~/components/common/common-title.vue";
import { CaseCollectorService } from "~/services/business-service/case-collector.service";
import { StatusConfigService } from "~/services/manage-service/status-config.service";
var collectionManageModule = namespace("collection-manage");
var FollowRecordCaseStatus = /** @class */ (function (_super) {
    __extends(FollowRecordCaseStatus, _super);
    function FollowRecordCaseStatus() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.collState = {};
        _this.collStates = {};
        _this.statusType = "";
        _this.loading = {
            state: false
        };
        _this.updateModel = {
            caseId: "",
            statusType: "",
            isAdd: "",
            status: "",
            caseType: "",
        };
        return _this;
    }
    FollowRecordCaseStatus.prototype.onCurrentCaseChanged = function (val) {
        // 刷新数据
        val && this.getStatus(val);
    };
    FollowRecordCaseStatus.prototype.onStatusTypeChange = function (val) {
        this.updateModel.status = "";
    };
    Object.defineProperty(FollowRecordCaseStatus.prototype, "stateSet", {
        get: function () {
            if (this.statusType === 0) {
                return this.collState.contactStatus;
            }
            else if (this.statusType === 1) {
                return this.collState.collectStatus;
            }
            else {
                return [];
            }
        },
        enumerable: false,
        configurable: true
    });
    FollowRecordCaseStatus.prototype.getStatus = function (val) {
        var _this = this;
        this.caseCollectorService.getCaseStatus(val, this.loading).subscribe(function (data) {
            _this.collStates = data;
        });
    };
    FollowRecordCaseStatus.prototype.mounted = function () {
        var _this = this;
        console.log("odfsdfsfsd999999" + this.caseId);
        console.log("odfsdfsfsd999999", this.otherData);
        this.statusConfigService.getStatusConfig(this.principalId, this.loading).subscribe(function (data) {
            _this.collState = data;
        });
    };
    FollowRecordCaseStatus.prototype.signCaseStateClick = function () {
        var _this = this;
        if (!this.updateModel.status) {
            this.$message.warning("您尚未选择任何信息，请选择");
            return;
        }
        this.updateModel.caseId = this.caseId;
        this.updateModel.isAdd = 1;
        this.updateModel.statusType = this.statusType === 0 ? "CONTACT_STATUS" : "COLLECT_STATUS";
        this.updateModel.caseType = this.otherData.caseType;
        this.caseCollectorService.updateStatus(this.updateModel, this.loading).subscribe(function (data) {
            _this.$message.success("标记成功");
            _this.getStatus(_this.caseId);
            _this.reset();
        });
    };
    FollowRecordCaseStatus.prototype.deleteCaseStateClick = function (item, flag) {
        var _this = this;
        this.updateModel.caseId = this.caseId;
        this.updateModel.isAdd = 0;
        this.updateModel.statusType = flag === 0 ? "CONTACT_STATUS" : "COLLECT_STATUS";
        this.updateModel.status = item;
        this.updateModel.caseType = this.otherData.caseType;
        this.caseCollectorService.updateStatus(this.updateModel, this.loading).subscribe(function (data) {
            _this.$message.success("删除成功");
            _this.getStatus(_this.caseId);
            _this.reset();
        });
    };
    FollowRecordCaseStatus.prototype.reset = function () {
        this.updateModel.caseId = "";
        this.updateModel.isAdd = "";
        this.updateModel.status = "";
        this.updateModel.statusType = "";
        this.updateModel.caseType = "";
    };
    __decorate([
        Dependencies(CaseCollectorService)
    ], FollowRecordCaseStatus.prototype, "caseCollectorService", void 0);
    __decorate([
        Dependencies(StatusConfigService)
    ], FollowRecordCaseStatus.prototype, "statusConfigService", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], FollowRecordCaseStatus.prototype, "updateCaseInfo", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordCaseStatus.prototype, "principalId", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordCaseStatus.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordCaseStatus.prototype, "otherData", void 0);
    __decorate([
        Watch("caseId", { immediate: true })
    ], FollowRecordCaseStatus.prototype, "onCurrentCaseChanged", null);
    __decorate([
        Watch("statusType", { immediate: true })
    ], FollowRecordCaseStatus.prototype, "onStatusTypeChange", null);
    FollowRecordCaseStatus = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon,
                CommonTitle: CommonTitle
            }
        })
    ], FollowRecordCaseStatus);
    return FollowRecordCaseStatus;
}(Vue));
export default FollowRecordCaseStatus;
