import { render, staticRenderFns } from "./label-item.vue?vue&type=template&id=3ed1d0ab&scoped=true"
import script from "./label-item.vue?vue&type=script&lang=ts"
export * from "./label-item.vue?vue&type=script&lang=ts"
import style0 from "./label-item.vue?vue&type=style&index=0&id=3ed1d0ab&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ed1d0ab",
  null
  
)

export default component.exports