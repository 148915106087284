var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import FollowRecordNote from "./follow-record-base/follow-record-note.vue";
import FollowRecordInsert from "./follow-record-base/follow-record-insert.vue";
import FollowRecordCaseStatus from "./follow-record-base/follow-record-case-status.vue";
var FollowRecordBase = /** @class */ (function (_super) {
    __extends(FollowRecordBase, _super);
    function FollowRecordBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Prop()
    ], FollowRecordBase.prototype, "isHistory", void 0);
    FollowRecordBase = __decorate([
        Component({
            components: {
                FollowRecordNote: FollowRecordNote,
                FollowRecordInsert: FollowRecordInsert,
                FollowRecordCaseStatus: FollowRecordCaseStatus
            }
        })
    ], FollowRecordBase);
    return FollowRecordBase;
}(Vue));
export default FollowRecordBase;
