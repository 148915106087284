var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import CommonTitle from "~/components/common/common-title.vue";
import SvgIcon from "~/components/common/svg-icon.vue";
import { Watch } from "vue-property-decorator";
var collectionManageModule = namespace("collection-manage");
var CardInfo = /** @class */ (function (_super) {
    __extends(CardInfo, _super);
    function CardInfo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeAry = [];
        return _this;
    }
    //  gotoDetail() {
    //    var url = "https://tjzx.liansdk.com/manager/#/caseDetailEdit?caseId=" + this.cardModel.caseNumber;
    //    window.open(url)
    //  }
    CardInfo.prototype.gotoDetail = function (item) {
        if (this.cardModel.caseNumber.indexOf('wxjk') != -1) {
            var url = "https://gen.habipet.com/inner/#/loanDetail?loanId=" + item;
            window.open(url);
        }
    };
    CardInfo.prototype.created = function () {
        if (null == this.cardModel.limitAmt) {
            this.cardModel.limitAmt = 1;
        }
        if ('' != this.cardModel.cardNoType && null != this.cardModel.cardNoType && undefined != this.cardModel.cardNoType) {
            this.typeAry = this.cardModel.cardNoType.split(',');
        }
    };
    CardInfo.prototype.onDateChange = function (newVal, oldVal) {
        console.log('cardModelcardModelcardModelcardModelcardModel');
        console.log(this.cardModel);
        if (this.cardModel.cardNoType.indexOf("、") != -1) {
            if ('' != this.cardModel.cardNoType && null != this.cardModel.cardNoType && undefined != this.cardModel.cardNoType) {
                this.typeAry = this.cardModel.cardNoType.split('、');
            }
        }
        else {
            if ('' != this.cardModel.cardNoType && null != this.cardModel.cardNoType && undefined != this.cardModel.cardNoType) {
                this.typeAry = this.cardModel.cardNoType.split(',');
            }
        }
    };
    __decorate([
        collectionManageModule.State
    ], CardInfo.prototype, "cardModel", void 0);
    __decorate([
        Watch("cardModel")
    ], CardInfo.prototype, "onDateChange", null);
    CardInfo = __decorate([
        Component({
            components: {
                CommonTitle: CommonTitle,
                SvgIcon: SvgIcon,
            },
        })
    ], CardInfo);
    return CardInfo;
}(Vue));
export default CardInfo;
