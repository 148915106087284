var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { domainService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var CaseFollowService = /** @class */ (function (_super) {
    __extends(CaseFollowService, _super);
    function CaseFollowService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 查询查找记录
     * @param data 查询参数实体
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    CaseFollowService.prototype.getFindRecord = function (caseId, page, sort, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.getFindRecord,
            data: {
                caseId: caseId,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 查询调记
     * @param data
     * @param page
     * @param sort
     * @param loading
     */
    CaseFollowService.prototype.getFollowRecord = function (data, page, sort, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.getFollowRecord,
            data: {
                caseId: data.caseId,
                type: data.type,
                followTimeStatrt: data.followTimeStatrt,
                followTimeEnd: data.followTimeEnd,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 查询跟进记录统计
     */
    CaseFollowService.prototype.getFollowRecordCount = function (data, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.getFollowRecordCount,
            data: {
                caseId: data.caseId,
            },
            loading: loading,
        });
    };
    /**
     * 查询其他跟进记录
     */
    CaseFollowService.prototype.getOtherFollowRecord = function (caseId, page, sort, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.getOtherFollowRecord,
            data: {
                caseId: caseId,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 查询委前跟进记录
     */
    CaseFollowService.prototype.getPreFollowRecord = function (data, page, sort, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.getPreFollowRecord,
            data: {
                caseId: data.caseId,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 添加查找记录
     */
    CaseFollowService.prototype.saveFindRecord = function (data, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.saveFindRecord,
            data: {
                caseId: data.caseId,
                content: data.content,
                findType: data.findType,
                isEffect: data.isEffect,
                target: data.target,
                targetName: data.targetName,
                informationUpdate: data.informationUpdate,
            },
            loading: loading,
        });
    };
    /**
     * 添加调记
     */
    CaseFollowService.prototype.saveFollowRecord = function (data, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.saveFollowRecord,
            data: this.setContactResult(data),
            loading: loading,
        });
    };
    /**
     * 添加其他跟进记录
     */
    CaseFollowService.prototype.saveOtherFollowRecord = function (data, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.saveOtherFollowRecord,
            data: {
                caseId: data.caseId,
                content: data.content,
                followTime: data.followTime,
                type: data.type,
                informationUpdate: data.informationUpdate,
            },
            loading: loading,
        });
    };
    /**
     * 根据电话号码查询联系人调记
     * @param data 查询参数实体
     */
    CaseFollowService.prototype.searchPersonalFollowRecord = function (data, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.searchPersonalFollowRecord,
            data: {
                phoneNumber: data.phoneNumber,
                targetName: data.targetName,
            },
            loading: loading,
        });
    };
    /**
     * 外访调记添加备注
     * @param data
     * @param loading
     */
    CaseFollowService.prototype.updateFollowContent = function (data, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.updateFollowContent,
            data: {
                id: data.id,
                remark: data.remark,
            },
            loading: loading,
        });
    };
    /**
     * 检测敏感词
     * @param data
     * @param loading
     */
    CaseFollowService.prototype.checkFollowRecord = function (data, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.checkFollowRecord,
            data: this.setContactResult(data),
            loading: loading,
        });
    };
    CaseFollowService.prototype.setContactResult = function (data) {
        // 处理联系结果为字符串
        // 如果设置数据源的控件类型错误，可能导致数据类型错误
        var contactResult = data.contactResult;
        if (contactResult instanceof Array) {
            // 正常的级联产生的数据处理
            contactResult = contactResult.join("/");
        }
        var contactState = data.contactState;
        if (contactState instanceof Array) {
            contactState = contactState.join("/");
        }
        return __assign(__assign({}, data), { contactResult: contactResult, contactState: contactState });
    };
    /**
     * 工单回复
     */
    CaseFollowService.prototype.replayOtherFollowRecord = function (data, currentId, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.replayOtherFollowRecord,
            data: {
                replayContent: data.replayContent,
                id: currentId,
            },
            loading: loading,
        });
    };
    /**
     * 历史跟进记录
     */
    CaseFollowService.prototype.getHisFollowRecord = function (followQuery, page, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.getHisFollowRecord,
            data: {
                caseId: followQuery.caseId,
            },
            page: page,
            loading: loading,
        });
    };
    /**
     * 删除调记
     * @param data
     */
    CaseFollowService.prototype.deletedFollowRecord = function (data) {
        return this.netService.send({
            server: domainService.caseFollowController.deletedFollowRecord,
            data: data,
        });
    };
    /**
     * 修改调记
     * @param data
     */
    CaseFollowService.prototype.updateFollowRecord = function (data) {
        return this.netService.send({
            server: domainService.caseFollowController.updateFollowRecord,
            data: {
                id: data.id,
                content: data.content,
                casePool: data.casePool,
                operatorTime: data.operatorTime,
                informationUpdate: data.informationUpdate,
            },
        });
    };
    /**
     * 共债信息在案 历史信息查询
     */
    CaseFollowService.prototype.getDebtFollowRecord = function (caseId, type) {
        return this.netService.send({
            server: domainService.caseFollowController.getDebtFollowRecord,
            data: {
                caseId: caseId,
                type: type,
            },
        });
    };
    /**
     * 添加外访记录
     */
    CaseFollowService.prototype.saveVisistFollowRecord = function (data, loading) {
        return this.netService.send({
            server: domainService.caseFollowController.saveVisistFollowRecord,
            data: {
                caseId: data.caseId,
                content: data.content,
                target: data.target,
                targetName: data.targetName,
                informationUpdate: data.informationUpdate,
                contactResult: data.contactResult,
                collectionType: data.collectionType,
                addrStatus: data.addrStatus,
                addrType: data.addrType,
                detail: data.detail,
                type: data.type,
                certificate: data.certificate,
                video: data.video,
                record: data.record,
                visitors: data.visitors,
            },
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], CaseFollowService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], CaseFollowService.prototype, "updateFollowContent", null);
    __decorate([
        Debounce()
    ], CaseFollowService.prototype, "checkFollowRecord", null);
    __decorate([
        Debounce()
    ], CaseFollowService.prototype, "replayOtherFollowRecord", null);
    __decorate([
        Debounce()
    ], CaseFollowService.prototype, "getHisFollowRecord", null);
    return CaseFollowService;
}(Service));
export { CaseFollowService };
