var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import PendingCollectionQuery from "~/components/collection-manage/execution/pending-collection-query.vue";
import RelationQuery from "~/components/collection-manage/execution/relation-query.vue";
import FollowQueryBase from "~/components/collection-manage/execution/follow-query-base.vue";
import CollectionHotkey from "~/components/collection-manage/execution/collection-hotkey.vue";
import FollowRecordBase from "~/components/collection-manage/execution/follow-record-base.vue";
import TopCaseBaseInfo from "~/components/collection-manage/execution/top-case-base-info.vue";
import CaseBaseInfo from "~/components/collection-manage/execution/case-base-info.vue";
import TopMessage from "~/components/collection-manage/execution/top-message.vue";
import BarrageSlide from "~/components/common/barrage-slide.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { CollectionCaseService } from "~/services/domain-service/collection-case.service";
import { HistoryCaseService } from "~/services/domain-service/history-case.service";
var collectionManageModule = namespace("collection-manage");
var CallIn = /** @class */ (function (_super) {
    __extends(CallIn, _super);
    function CallIn() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isApper = true;
        return _this;
    }
    CallIn.prototype.onCaseIdChanged = function (val) {
        if (!val)
            return;
        var queryService = this.isHistory ? this.historyCaseService : this.collectionCaseService;
        queryService.caseCollectionSearch(this.caseId).subscribe(this.updateCaseInfo);
    };
    CallIn.prototype.isDisplay = function (val) {
        this.isApper = val;
    };
    Object.defineProperty(CallIn.prototype, "isHistory", {
        /**
         * 是否是历史案件进入，通过OhterData 传入
         */
        get: function () {
            return this.queryOtherData("isHistory");
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CallIn.prototype, "isFast", {
        /**
         * 是否快速查询进入
         */
        get: function () {
            return this.queryOtherData("isFast");
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Dependencies(CollectionCaseService)
    ], CallIn.prototype, "collectionCaseService", void 0);
    __decorate([
        Dependencies(HistoryCaseService)
    ], CallIn.prototype, "historyCaseService", void 0);
    __decorate([
        collectionManageModule.State
    ], CallIn.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.Getter
    ], CallIn.prototype, "queryOtherData", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], CallIn.prototype, "updateCaseInfo", void 0);
    __decorate([
        Watch("caseId", { immediate: true })
    ], CallIn.prototype, "onCaseIdChanged", null);
    CallIn = __decorate([
        Layout("default"),
        Component({
            components: {
                PendingCollectionQuery: PendingCollectionQuery,
                RelationQuery: RelationQuery,
                FollowQueryBase: FollowQueryBase,
                CollectionHotkey: CollectionHotkey,
                FollowRecordBase: FollowRecordBase,
                TopCaseBaseInfo: TopCaseBaseInfo,
                CaseBaseInfo: CaseBaseInfo,
                TopMessage: TopMessage,
                BarrageSlide: BarrageSlide,
            },
        })
    ], CallIn);
    return CallIn;
}(Vue));
export default CallIn;
