var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import FileUpload from '~/components/common/file-upload.vue';
import { Dependencies } from '~/core/decorator';
import { namespace } from 'vuex-class';
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
import { Emit, Prop } from 'vue-property-decorator';
var collectionManageModule = namespace("collection-manage");
var WaiverApp = /** @class */ (function (_super) {
    __extends(WaiverApp, _super);
    function WaiverApp() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.fileList = [];
        _this.model = {
            applyAmount: '',
            caseId: _this.caseId,
            flowType: 'DERATE_APPLY',
            fileId: '',
            applyRemark: '',
            caseType: ''
        };
        _this.rules = {
            applyAmount: [
                { required: true, message: '请输入申请减免金额', trigger: 'blur' },
                { validator: _this.$validator.money, trigger: 'blur' },
            ],
            applyRemark: [{ required: true, message: "请输入申请说明", trigger: "blur" }]
        };
        return _this;
        // /**
        //  * 文件上传成功后回调函数
        //  * @param fileId
        //  * @param data
        //  */
        // onUploadSuccess(id, data) {
        //   if (data) {
        //     this.model.fileId = data.id;
        //     this.model.fileName = data.originalName;
        //   }
        // }
    }
    WaiverApp.prototype.close = function () {
        var form = this.$refs['form'];
        this.model.fileId = '';
        this.fileList = [];
        form.resetFields();
        var upload = this.$refs['upload_import'];
        upload.reset();
    };
    WaiverApp.prototype.commit = function () {
        var _this = this;
        var form = this.$refs['form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            var fileIds = _this.fileList.map(function (x) { return x.response.id; });
            _this.loading.state = true;
            var model = {
                caseId: _this.caseId,
                flowType: _this.model.flowType,
                applyAmount: _this.model.applyAmount,
                fileIds: fileIds,
                applyRemark: _this.model.applyRemark,
                caseType: _this.otherData.caseType
            };
            _this.caseApplyManageService.caseApplyOperated(model, _this.loading)
                .subscribe(function (data) {
                _this.$message.success('减免申请成功');
                _this.close();
            });
        });
    };
    __decorate([
        Dependencies(CaseApplyManageService)
    ], WaiverApp.prototype, "caseApplyManageService", void 0);
    __decorate([
        Prop({})
    ], WaiverApp.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.Getter
    ], WaiverApp.prototype, "principalInfo", void 0);
    __decorate([
        collectionManageModule.State
    ], WaiverApp.prototype, "otherData", void 0);
    __decorate([
        Emit('close')
    ], WaiverApp.prototype, "close", null);
    WaiverApp = __decorate([
        Component({
            components: {
                FileUpload: FileUpload,
            },
        })
    ], WaiverApp);
    return WaiverApp;
}(Vue));
export default WaiverApp;
