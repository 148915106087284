var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
var PaymentRecordService = /** @class */ (function () {
    function PaymentRecordService() {
    }
    /**
     * 查询还款记录
     */
    PaymentRecordService.prototype.findPaymentRecord = function (caseId, loading) {
        return this.netService.send({
            server: businessService.paymentRecordController.findPaymentRecord,
            data: {
                caseId: caseId
            },
            loading: loading
        });
    };
    /**
     * 查询还款记录
     */
    PaymentRecordService.prototype.caseBillRecord = function (data) {
        return this.netService.send({
            server: businessService.paymentRecordController.caseBillRecord,
            data: data
        });
    };
    /**
     * 查询还款记录
     */
    PaymentRecordService.prototype.findAllPaymentRecords = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.paymentRecordController.findAllPaymentRecords,
            data: {
                principalId: data.principalId,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                cardNo: data.cardNo,
                caseNumber: data.caseNumber,
                batchNumber: data.batchNumber,
                departCode: data.departCode,
                stage: data.stage,
                collectorUsername: data.collectorUsername,
                payDateStart: data.payDate[0],
                payDateEnd: data.payDate[1],
                payAmtStart: data.payAmt.min,
                payAmtEnd: data.payAmt.max
            },
            loading: loading,
            page: page,
            sort: sort
        });
    };
    __decorate([
        Inject(NetService)
    ], PaymentRecordService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], PaymentRecordService.prototype, "findPaymentRecord", null);
    __decorate([
        Debounce()
    ], PaymentRecordService.prototype, "findAllPaymentRecords", null);
    return PaymentRecordService;
}());
export { PaymentRecordService };
