var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Dependencies } from "~/core/decorator";
import { CaseInfoService } from "~/services/business-service/case-info.service";
import SvgIcon from "~/components/common/svg-icon.vue";
import CommonTitle from "~/components/common/common-title.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
var collectionManageModule = namespace("collection-manage");
var PendingCollectionQuery = /** @class */ (function (_super) {
    __extends(PendingCollectionQuery, _super);
    function PendingCollectionQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.filterName = "";
        _this.dataSource = [];
        _this.rowIndex = -1;
        _this.loading = {
            state: false
        };
        _this.isForm = false; //外呼时显示电话号码输入框
        _this.companyid = "18"; //企业ID
        _this.phoneNumber = "";
        _this.seatLocalStorage = "";
        _this.zuoxi = "";
        _this.seatState = "";
        _this.hidden = true;
        _this.hover = false;
        return _this;
    }
    Object.defineProperty(PendingCollectionQuery.prototype, "leftLogo", {
        get: function () {
            return this.queryOtherData("leftLogo");
        },
        enumerable: false,
        configurable: true
    });
    PendingCollectionQuery.prototype.mounted = function () {
        this.refreshData();
        this.pageService.layout = " pager"; //由于空间有限去掉分页右侧输入框
        this.pageService.pageCount = 5; //由于空间有限缩小展示数量
    };
    PendingCollectionQuery.prototype.refreshData = function () {
        var _this = this;
        this.caseInfoService
            .caseCollectionStatus(this.collectionData.queryData, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSource = data;
        });
    };
    PendingCollectionQuery.prototype.onRowClick = function (row, event, column) {
        this.updateCaseId(row.id); // 更新当前案件id
        var otherData = {
            sort: this.sortService.clone(),
            caseType: row.caseType
        };
        this.updateOtherData(otherData);
    };
    PendingCollectionQuery.prototype.rowStyle = function (_a) {
        var row = _a.row, rowIndex = _a.rowIndex;
        if (row.id == this.caseId) {
            this.rowIndex = rowIndex;
            return {
                color: "#006666",
                cursor: "pointer"
            };
        }
        return {
            cursor: "pointer"
        };
    };
    PendingCollectionQuery.prototype.searchUser = function () {
        this.pageService.reset(); //重置分页
        this.collectionData.queryData.personalName = this.filterName;
        this.refreshData();
    };
    __decorate([
        Dependencies(CaseInfoService)
    ], PendingCollectionQuery.prototype, "caseInfoService", void 0);
    __decorate([
        Dependencies(PageService)
    ], PendingCollectionQuery.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], PendingCollectionQuery.prototype, "sortService", void 0);
    __decorate([
        collectionManageModule.Getter
    ], PendingCollectionQuery.prototype, "collectionData", void 0);
    __decorate([
        collectionManageModule.State
    ], PendingCollectionQuery.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], PendingCollectionQuery.prototype, "updateCaseId", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], PendingCollectionQuery.prototype, "updateOtherData", void 0);
    __decorate([
        collectionManageModule.Getter
    ], PendingCollectionQuery.prototype, "queryOtherData", void 0);
    __decorate([
        Prop()
    ], PendingCollectionQuery.prototype, "isApper", void 0);
    PendingCollectionQuery = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                SvgIcon: SvgIcon,
                CommonTitle: CommonTitle
            }
        })
    ], PendingCollectionQuery);
    return PendingCollectionQuery;
}(Vue));
export default PendingCollectionQuery;
