var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CommonTitle from "~/components/common/common-title.vue";
import SvgIcon from "~/components/common/svg-icon.vue";
var collectionManageModule = namespace("collection-manage");
var AccountInfo = /** @class */ (function (_super) {
    __extends(AccountInfo, _super);
    function AccountInfo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 卡号
         */
        _this.card = [];
        _this.activeFlagAccount = ""; //动态绑定class标识
        _this.activeFlagNum = ""; //动态绑定class标识
        return _this;
    }
    Object.defineProperty(AccountInfo.prototype, "accNumber", {
        get: function () {
            return this.accountNumber;
        },
        enumerable: false,
        configurable: true
    });
    AccountInfo.prototype.mounted = function () {
        this.setCard(this.accountNumber);
        this.onChangeAccount(this.card[0]);
    };
    AccountInfo.prototype.accountNumberChange = function (val) {
        var _this = this;
        if (!val.length)
            return;
        var params = val.filter(function (x) { return x.account === _this.currentCase.account; });
        this.updateCardModel(params[0]);
        this.setCard(this.currentCase.account);
        this.activeFlagAccount = this.currentCase.account;
        this.activeFlagNum = this.card[0].cardNo;
    };
    AccountInfo.prototype.setCard = function (accountNumber) {
        var _this = this;
        if (!accountNumber)
            return;
        this.card = [];
        var cardSet = this.cardInfo.filter(function (e) { return e.account === accountNumber; });
        cardSet.map(function (e) {
            var cardModel = {
                cardNo: e.cardNo,
                no: e.account,
                amt: e.leftAmt
            };
            _this.card.push(cardModel);
        });
    };
    AccountInfo.prototype.onChangeAccount = function (val) {
        this.activeFlagAccount = val.no;
        this.setCard(val.no);
        var cards = this.cardInfo.filter(function (e) { return e.account === val.no; });
        this.activeFlagNum = cards[0].cardNo;
        this.onChangeCard(cards[0]);
    };
    AccountInfo.prototype.onChangeCard = function (val) {
        this.activeFlagNum = val.cardNo;
        var cardSet = this.cardInfo.filter(function (e) { return e.cardNo === val.cardNo; });
        this.updateCardModel(cardSet[0]);
    };
    __decorate([
        collectionManageModule.State
    ], AccountInfo.prototype, "cardInfo", void 0);
    __decorate([
        collectionManageModule.State
    ], AccountInfo.prototype, "accountInfo", void 0);
    __decorate([
        collectionManageModule.State
    ], AccountInfo.prototype, "accountNumber", void 0);
    __decorate([
        collectionManageModule.State
    ], AccountInfo.prototype, "currentCase", void 0);
    __decorate([
        collectionManageModule.State
    ], AccountInfo.prototype, "currentCardNo", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], AccountInfo.prototype, "updateCardModel", void 0);
    __decorate([
        Watch("cardInfo", {
            immediate: true
        })
    ], AccountInfo.prototype, "accountNumberChange", null);
    AccountInfo = __decorate([
        Component({
            components: {
                CommonTitle: CommonTitle,
                SvgIcon: SvgIcon
            }
        })
    ], AccountInfo);
    return AccountInfo;
}(Vue));
export default AccountInfo;
