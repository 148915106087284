var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Prop, Watch } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseFollowService } from "~/services/domain-service/case-follow.service";
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
var collectionManageModule = namespace("collection-manage");
var FindInformation = /** @class */ (function (_super) {
    __extends(FindInformation, _super);
    function FindInformation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataSet = [];
        return _this;
    }
    FindInformation.prototype.onCaseIdChanged = function (val) {
        val && this.refreshData();
    };
    FindInformation.prototype.refreshData = function () {
        var _this = this;
        var params = {
            certificateNo: this.certificateNo
        };
        this.caseFollowRecordService.getAllFindRecord(params, this.pageService, this.sortService)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    FindInformation.prototype.activated = function () {
        this.refreshData();
    };
    __decorate([
        Dependencies(PageService)
    ], FindInformation.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], FindInformation.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseFollowService)
    ], FindInformation.prototype, "caseFollowService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], FindInformation.prototype, "caseFollowRecordService", void 0);
    __decorate([
        Prop({
            required: true,
            default: ""
        })
    ], FindInformation.prototype, "certificateNo", void 0);
    __decorate([
        Prop({
            required: true,
            default: ""
        })
    ], FindInformation.prototype, "personalId", void 0);
    __decorate([
        Watch("certificateNo", { immediate: true })
    ], FindInformation.prototype, "onCaseIdChanged", null);
    FindInformation = __decorate([
        Component({
            components: {
                DataBox: DataBox
            }
        })
    ], FindInformation);
    return FindInformation;
}(Vue));
export default FindInformation;
