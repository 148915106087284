import { render, staticRenderFns } from "./follow-record-other.vue?vue&type=template&id=3d465c60&scoped=true"
import script from "./follow-record-other.vue?vue&type=script&lang=ts"
export * from "./follow-record-other.vue?vue&type=script&lang=ts"
import style1 from "./follow-record-other.vue?vue&type=style&index=1&id=3d465c60&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d465c60",
  null
  
)

export default component.exports