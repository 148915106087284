var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Prop, Watch } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseFollowService } from "~/services/domain-service/case-follow.service";
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import ViewRecoredFiles from "./view-recored-files.vue";
var collectionManageModule = namespace("collection-manage");
var FollowAllQueryMini = /** @class */ (function (_super) {
    __extends(FollowAllQueryMini, _super);
    function FollowAllQueryMini() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataSet = [];
        _this.followQuery = {
            certificateNo: "",
            collectionWay: 1,
            personalId: ""
        };
        _this.loading = { state: false };
        _this.currentCase = {};
        _this.phoneModel = {};
        _this.dialog = {
            certificate: false,
            record: false,
            video: false,
            recordFiles: false,
            updateRecord: false
        };
        return _this;
    }
    /**
     * 调解执行页面使用
     */
    FollowAllQueryMini.prototype.onRecordCountChange = function () {
        this.refreshData();
    };
    FollowAllQueryMini.prototype.collectionDelete = function (id) {
        var _this = this;
        this.$confirm("是否确认删除?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            var deleteModel = {
                id: id,
                caseType: _this.otherData.caseType
            };
            _this.caseFollowRecordService
                .deleteCaseFollowRecord(deleteModel)
                .subscribe(function (data) {
                _this.$message.success("删除成功!");
                _this.refreshData();
            });
        })
            .catch(function () { });
    };
    FollowAllQueryMini.prototype.onCaseIdChange = function (val) {
        val && this.refreshData();
    };
    FollowAllQueryMini.prototype.onPersonalIdChange = function (val) {
        val && this.refreshData();
    };
    FollowAllQueryMini.prototype.viewRecordFiles = function (row) {
        this.currentCase = row;
        this.dialog.recordFiles = true;
    };
    FollowAllQueryMini.prototype.viewUpdateRecord = function (row) {
        this.currentCase = row;
        this.dialog.updateRecord = true;
    };
    FollowAllQueryMini.prototype.updateCommit = function () {
        var _this = this;
        var params = {
            operateTime: this.phoneModel.operateTime,
            content: this.phoneModel.content,
            caseId: this.currentCase.caseId,
            id: this.currentCase.id,
            caseType: "TEL_CASE",
        };
        this.caseFollowRecordService.updateCaseFollowRecord(params).subscribe(function (data) {
            _this.$message.success("更新调记成功");
            _this.dialog.updateRecord = false;
            _this.refreshData();
        });
    };
    /**
     *查看看凭证
     */
    FollowAllQueryMini.prototype.certificateDetails = function (follow, type) {
        this.currentCase = follow;
        if (type === 1) {
            this.dialog.certificate = true;
        }
        else if (type === 2) {
            this.dialog.record = true;
        }
        else {
            this.dialog.video = true;
        }
    };
    /**
     *下载
     */
    FollowAllQueryMini.prototype.downLoadFile = function (follow, type) {
        var _this = this;
        this.currentCase = follow;
        if (type === 2) {
            this.currentCase.record.forEach(function (x) {
                _this.uploadFileService
                    .getFileStreamById(x.id, x.fileName)
                    .subscribe(function () { return _this.$message.success("下载成功"); });
            });
        }
        else {
            this.currentCase.video.forEach(function (x) {
                _this.uploadFileService
                    .getFileStreamById(x.id, x.fileName)
                    .subscribe(function () { return _this.$message.success("下载成功"); });
            });
        }
    };
    FollowAllQueryMini.prototype.cellStyle = function (row) {
        if (row.row.contactResult === "承诺还款" && row.columnIndex == 2) {
            return { color: "#fc8144 !important" };
        }
        if (row.row.contactResult === "已还款" && row.columnIndex == 2) {
            return { color: "#00c100 !important" };
        }
    };
    FollowAllQueryMini.prototype.refreshData = function () {
        var _this = this;
        this.followQuery.certificateNo = this.certificateNo;
        // this.followQuery.personalId = this.personalId;
        this.loading.state = true;
        if (!this.followQuery.certificateNo)
            return;
        this.caseFollowRecordService
            .getAllCaseFollowRecord(this.followQuery, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        }, function (err) { return _this.$message.error(err.msg); });
    };
    FollowAllQueryMini.prototype.activated = function () {
        this.refreshData();
    };
    __decorate([
        Dependencies(PageService)
    ], FollowAllQueryMini.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], FollowAllQueryMini.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseFollowService)
    ], FollowAllQueryMini.prototype, "caseFollowService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], FollowAllQueryMini.prototype, "caseFollowRecordService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], FollowAllQueryMini.prototype, "uploadFileService", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowAllQueryMini.prototype, "recordCount", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowAllQueryMini.prototype, "otherData", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], FollowAllQueryMini.prototype, "certificateNo", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], FollowAllQueryMini.prototype, "personalId", void 0);
    __decorate([
        Watch("recordCount")
    ], FollowAllQueryMini.prototype, "onRecordCountChange", null);
    __decorate([
        Watch("caseId", { immediate: true })
    ], FollowAllQueryMini.prototype, "onCaseIdChange", null);
    __decorate([
        Watch("personalId", { immediate: true })
    ], FollowAllQueryMini.prototype, "onPersonalIdChange", null);
    FollowAllQueryMini = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                ViewRecoredFiles: ViewRecoredFiles
            }
        })
    ], FollowAllQueryMini);
    return FollowAllQueryMini;
}(Vue));
export default FollowAllQueryMini;
