var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Prop, Watch } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseFollowService } from "~/services/domain-service/case-follow.service";
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
import ViewRecoredFiles from "./view-recored-files.vue";
var collectionManageModule = namespace("collection-manage");
var PhoneFollowQuery = /** @class */ (function (_super) {
    __extends(PhoneFollowQuery, _super);
    function PhoneFollowQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentRow = {};
        _this.dialog = {
            recordFiles: false,
            updateRecord: false,
        };
        _this.phoneModel = {};
        _this.dataSet = [];
        _this.queryParamsModel = {
            certificateNo: "",
            collectionWay: 1,
            type: "TEL",
        };
        return _this;
    }
    PhoneFollowQuery.prototype.onCaseIdChanged = function (val) {
        val && this.refreshData();
    };
    PhoneFollowQuery.prototype.cellStyle = function (row) {
        if (row.row.contactResult === "承诺还款" && row.columnIndex == 2) {
            return { color: "#fc8144 !important" };
        }
        if (row.row.contactResult === "已还款" && row.columnIndex == 2) {
            return { color: "#00c100 !important" };
        }
    };
    PhoneFollowQuery.prototype.viewRecordFiles = function (row) {
        this.currentRow = row;
        this.dialog.recordFiles = true;
    };
    PhoneFollowQuery.prototype.viewUpdateRecord = function (row) {
        this.currentRow = row;
        this.dialog.updateRecord = true;
    };
    PhoneFollowQuery.prototype.updateCommit = function () {
        var _this = this;
        var params = {
            operateTime: this.phoneModel.operateTime,
            content: this.phoneModel.content,
            caseId: this.currentRow.caseId,
            id: this.currentRow.id,
            caseType: "TEL_CASE",
        };
        this.caseFollowRecordService.updateCaseFollowRecord(params).subscribe(function (data) {
            _this.$message.success("更新调记成功");
            _this.dialog.updateRecord = false;
            _this.refreshData();
        });
    };
    PhoneFollowQuery.prototype.refreshData = function () {
        var _this = this;
        if (!this.certificateNo)
            return;
        this.queryParamsModel.certificateNo = this.certificateNo;
        // this.queryParamsModel.personalId = this.personalId;
        this.caseFollowRecordService
            .getAllCaseFollowRecord(this.queryParamsModel, this.pageService, this.sortService)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    PhoneFollowQuery.prototype.activated = function () {
        this.refreshData();
    };
    __decorate([
        Dependencies(PageService)
    ], PhoneFollowQuery.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], PhoneFollowQuery.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseFollowService)
    ], PhoneFollowQuery.prototype, "caseFollowService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], PhoneFollowQuery.prototype, "caseFollowRecordService", void 0);
    __decorate([
        Prop({
            required: true,
            default: "",
        })
    ], PhoneFollowQuery.prototype, "certificateNo", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], PhoneFollowQuery.prototype, "personalId", void 0);
    __decorate([
        Watch("certificateNo", { immediate: true })
    ], PhoneFollowQuery.prototype, "onCaseIdChanged", null);
    PhoneFollowQuery = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                ViewRecoredFiles: ViewRecoredFiles,
            },
        })
    ], PhoneFollowQuery);
    return PhoneFollowQuery;
}(Vue));
export default PhoneFollowQuery;
