var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { managementService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var ProcessConfigService = /** @class */ (function (_super) {
    __extends(ProcessConfigService, _super);
    function ProcessConfigService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 配置流程查询
     */
    ProcessConfigService.prototype.configFlowQuery = function (data, page, sort, loading) {
        return this.netService.send({
            server: managementService.processConfigController.configFlowQuery,
            data: {
                configName: data.configName,
                configType: data.configType
            },
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 新建配置流程
     */
    ProcessConfigService.prototype.createConfigFlow = function (data, loading) {
        return this.netService.send({
            server: managementService.processConfigController.createConfigFlow,
            data: {
                configMap: data.configMap,
                configName: data.configName,
                configType: data.configType,
                level: data.level,
                applyState: data.applyState,
                principals: data.principals,
            },
            loading: loading
        });
    };
    /**
     * 删除配置流程
     */
    ProcessConfigService.prototype.deleteConfigFlow = function (id, loading) {
        return this.netService.send({
            server: managementService.processConfigController.deleteConfigFlow,
            append: [id],
            loading: loading
        });
    };
    /**
     * 查询配置流程详情
     */
    ProcessConfigService.prototype.findConfigFlow = function (data, loading) {
        return this.netService.send({
            server: managementService.processConfigController.findConfigFlow,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改配置流程
     */
    ProcessConfigService.prototype.modifyConfigFlow = function (data, loading) {
        return this.netService.send({
            server: managementService.processConfigController.modifyConfigFlow,
            data: {
                configMap: data.configMap,
                configName: data.configName,
                configType: data.configType,
                id: data.id,
                level: data.level,
                applyState: data.applyState,
                principals: data.principals,
            },
            loading: loading
        });
    };
    /**
     * 根据申请类型和审批类型查询唯一流程
     */
    ProcessConfigService.prototype.findConfigFlowByType = function (applyType, principal, loading) {
        return this.netService.send({
            server: managementService.processConfigController.findConfigFlowByType,
            data: {
                applyType: applyType,
                principal: principal,
            },
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], ProcessConfigService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], ProcessConfigService.prototype, "createConfigFlow", null);
    __decorate([
        Debounce()
    ], ProcessConfigService.prototype, "deleteConfigFlow", null);
    __decorate([
        Debounce()
    ], ProcessConfigService.prototype, "modifyConfigFlow", null);
    __decorate([
        Debounce()
    ], ProcessConfigService.prototype, "findConfigFlowByType", null);
    return ProcessConfigService;
}(Service));
export { ProcessConfigService };
